@charset "utf-8";
/* CSS Document */
/* Variables */
:root {
	--bs-body-font-family:'helveticanowdisplay_regularRg';
	--bs-heading-font-family:'helvetica_now_displaybold';
	--bs-heading-medium-font-family:'helvetica_now_displaymedium';
	--bs-light-font-family:'helvetica_now_displaylight';
	--bs-body-font-size:18px;
	--bs-white:#ffffff;
	--bs-orange:#ef4c1c;
	--bs-orange-light:#F77B00;
	--bs-dark:#1f1f1f;
	--bs-body-color:#262626;
	--bs-heading-color:#000000;
	--bs-gray-0:#FAFCFF;
	--bs-gray-100:#F8F8F8;
	--bs-gray-200:#fbfbfb;
	--bs-gray-300:#f7f7f7;
	--bs-link-color-rgb:239,76,28;
	--bs-link-hover-color-rgb:208,67,26;
	--bs-primary:#0072ff;
	--bs-clr-light:#4d4d4d;
	--bs-disabled-clr:#7B7B7B;
  --bs-d-primary-blue:#0061D9
}

.btn {
	font-family:var(--bs-heading-font-family); letter-spacing:0.36px;
	--bs-btn-padding-x:2.2rem;
	--bs-btn-padding-y:0.8rem;
}

.clr-darkgrey{color:#202020}
.navbar-toggler{border-radius: 0px;}
.btn.arrow::after { background:url(../img/arrow-right-white.svg) no-repeat 0 0 / cover; content:""; display:inline-block; width:15px; height:13px; margin-left:10px; position:relative; z-index:9; }
.small-blue-head{color:var(--bs-primary);}
.link::after { background:url(../img/arrow-right-orange.svg) no-repeat 0 0 / cover; content:""; display:inline-block; width:15px; height:13px; margin-left:10px; }
.link:hover { color:rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1)) !important; }
.link:hover::after { margin-left:15px; }
/* client_sec */
.clients_sec img:hover{transform: scale(1.08);}
.l-32{line-height: 38px;}
/* client_sec end */
.btn-primary {
	--bs-btn-color: #fff !important;
	--bs-btn-bg: #ef4c1c !important;
	--bs-btn-border-color: #ef4c1c !important;
	--bs-btn-hover-color: #fff !important;
	--bs-btn-hover-bg: #ef4c1c !important;
	--bs-btn-hover-border-color: #ef4c1c !important;
	--bs-btn-focus-shadow-rgb: 49,132,253 !important;
	--bs-btn-active-color: #fff !important;
	--bs-btn-active-bg: #ef4c1c !important;
	--bs-btn-active-border-color: #ef4c1c !important;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
	--bs-btn-disabled-color: #fff !important;
	--bs-btn-disabled-bg: #c0360d !important;
	--bs-btn-disabled-border-color: #c0360d !important;
	border-radius: 50rem !important;
	position:relative !important;
	overflow:hidden !important;
	transition:0.2s !important;
  background-color: #ef4c1c !important;
  border-color: #ef4c1c !important;
  padding: 12px;
}
.orng-clr{color:#ef4c1c}
.btn-primary span { position:relative; }
.btn-primary::before { content:""; background:#d0431a; position:absolute; left:0; height:100%; width:0; top:0; }
.btn-primary:hover::before { width:100%; }
.l-70{line-height: 70px;}
.gray-0 { background:var(--bs-gray-0); }
.gray-100 { background:var(--bs-gray-100); }
.gray-200 { background:var(--bs-gray-200); }
.gray-300 { background:var(--bs-gray-300); }

.dark-0 { background:var(--bs-dark); }

.py-6 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
.pt-6 { padding-top: 3rem !important; }
.pb-6 { padding-bottom: 3rem !important; }
.my-6 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
.mt-6 { margin-top: 3rem !important; }
.mb-6 { margin-bottom: 3rem !important; }
/* Variables */

@font-face {
    font-family: 'helvetica_now_displaylight';
    src: url('../fonts/helveticanowdisplay-light-webfont.woff2') format('woff2'),
         url('../fonts/helveticanowdisplay-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helveticanowdisplay_regularRg';
    src: url('../fonts/helveticanowdisplay-regular-webfont.woff2') format('woff2'),
         url('../fonts/helveticanowdisplay-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helvetica_now_displaymedium';
    src: url('../fonts/helveticanowdisplay-medium-webfont.woff2') format('woff2'),
         url('../fonts/helveticanowdisplay-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helvetica_now_displaybold';
    src: url('../fonts/helveticanowdisplay-bold-webfont.woff2') format('woff2'),
         url('../fonts/helveticanowdisplay-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

*, ::after, ::before {
transition:0.5s;
}
body { letter-spacing:0.36px;font-family:var(--bs-body-font-family) }
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 { font-family:var(--bs-heading-font-family); margin:0 0 25px;  }

h1 { font-size:40px; }
h2 { font-size:40px; line-height:1.3; }
h3 { font-size:27px; line-height:1.3; }
h4 { font-size:24px; line-height:1.3; }
h5 { font-size:22px; }
h6 { font-size:20px; }

.h2 { font:24px/1.4 var(--bs-body-font-family); }
.h4 { font:22px/1.4 var(--bs-heading-medium-font-family); }
.h6{font-size: 20px;}
.h_head { font-size:50px; }
.f24 { font-size:20px; }
.f30 { font-size:24px; }
.f60 { font-size:38px; }
.f36{font-size: 36px;}
.w-45{width: 60px;}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{padding-right: calc(var(--bs-gutter-x) * 1);padding-left: calc(var(--bs-gutter-x) * 1);}
.l-26{line-height: 26px;}
.linkstyle a{display: inline-block;}
.linkstyle  a::after { background:#fff; display:block; content:""; height:1px; width:0; }
  a:hover::after { width:100%; }
.font-light { font-family:var(--bs-light-font-family) !important; }
.font-regular { font-family:var(--bs-body-font-family) !important; }
.font-medium { font-family:var(--bs-heading-medium-font-family) !important; }
.font-bold { font-family:var(--bs-heading-font-family) !important; }

.clrwhite { color:#fff !important; }
.clrlightgrey { color:var(--bs-clr-light) !important; }
.bgwhite { background:#fff !important; }

a { text-decoration:none; }
.small, small { font-size:.875rem; }
.small-head { font:22px var(--bs-heading-font-family); letter-spacing:0.44px; margin-bottom:20px;color:#000 }
.blr-clr{color:var(--bs-primary)}
label { font:15px 'helvetica_now_displaybold'; margin:0 0 7px; }
.form-group .form-check label { font-family:'helveticanowdisplay_regularRg'; }
.form-control { border-color: #989898; border-radius: 100px; color:#989898; font-size: 17px; padding: 13px 26px; line-height: 1; letter-spacing: inherit; }
.form-control:focus { box-shadow:0 0; border-color:#989898; }
textarea { resize:none; }
.form-control::placeholder { color:#989898; opacity:1; }
.form-control:-ms-input-placeholder { color:#989898; }
.form-control::-ms-input-placeholder { color:#989898; }
.form-control:focus::placeholder,
.form-control:focus:-ms-input-placeholder,
.form-control:focus::-ms-input-placeholder { color:#262626; }


/* Animation */
@keyframes updown {
0%, 100% {
transform: translateY(0);
}
50% {
transform: translateY(10px);
}
}
/* Animation */



/*Header*/
body { padding-top: 110px; }
.header--fixed { position:fixed; z-index:10; right:0; left:0; top:0; }
.headroom { transition:transform .25s ease-in-out; will-change:transform;border-bottom: 1px solid #E2E2E2; }
.headroom--pinned { transform:translateY(0); }
.headroom--unpinned {  }
.headroom--unpinned { transform:translateY(0) !important; border:none}
.headroom--pinned { background:#fff; }

.logo { position: relative; display: block; }
.header .headroom--unpinned .logo_sec .logo { display:block; overflow:hidden; }
.header .logo_sec .logo_big { display:block; }
.header .headroom--unpinned .logo_sec .logo_big { visibility: hidden; opacity: 0; }
.header .logo_sec .logo_small { opacity: 0; height: auto; position: absolute; top: 0; left: 0; visibility: hidden; }
.header .headroom--unpinned .logo_sec .logo_small { visibility: visible; opacity: 1;transform: translateY(0); }
.header .headroom--unpinned .menu_sec{ visibility: hidden; opacity: 0;transform: translateY(0); }

.navbar-toggler span { width:40px; height:4px; background:var(--bs-primary); display: block; margin:9px auto;
-webkit-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}
.navbar-toggler span:first-child { margin-top: 0; }
.navbar-toggler:hover { cursor: pointer; }
.navbar-toggler[aria-expanded="true"] span:nth-child(2){ opacity: 0; }
.navbar-toggler[aria-expanded="true"] span:nth-child(1){ 
-webkit-transform: translateY(13px) rotate(45deg);
-ms-transform: translateY(13px) rotate(45deg);
-o-transform: translateY(13px) rotate(45deg);
transform: translateY(13px) rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] span:nth-child(3){
-webkit-transform: translateY(-13px) rotate(-45deg);
-ms-transform: translateY(-13px) rotate(-45deg);
-o-transform: translateY(-13px) rotate(-45deg);
transform: translateY(-13px) rotate(-45deg);
}

.navbar .navbar-nav .nav-item { margin:0 5px; }
.navbar .navbar-nav .nav-link { color:#000; display:inline-block; font-size:17px; letter-spacing:0.43px; padding:36px 13px; }
.navbar .navbar-nav .nav-link  + [data-bs-toggle="dropdown"] { border:0; display:inline-block; height:8px; width:8px; border:2px solid #000; border-left:0; border-top:0; transform:rotate(45deg); border-radius:0px; margin-left:.500em; vertical-align:middle; font-size:0; }
.dropdown-mega .dropdown-menu { border-radius:0; border:0; left:0; width:100%; }
.navbar .navbar-nav .nav-item .btn { padding-left:25px; padding-right:25px; margin-left:15px; } 
.navbar .navbar-nav .nav-item.last .nav-link::before { display:none; }
.dropdown-menu { border:0; }
.dropdown-menu li a { padding:7px 10px; }

.menu_head,
.dropdown-menu .list-group,
.dropdown-menu .cont { padding-left:40px;text-wrap: wrap;font-size: 15px;font-weight: 500;letter-spacing: 1px;line-height: 25px;font-family: var(--bs-body-font-family); }
.dropdown-menu .list-group .list-group-item { border:0; padding:5px 0; }
.dropdown-menu .list-group .list-group-item,
.dropdown-item,
.menu_head { font:17px var(--bs-light-font-family);color: #000; }
.menu_head { margin:0 0 15px; position:relative; }
.block_link { color:#000; }
.block_link:hover { color: var(--bs-primary); }
.navbar .navbar-nav .nav-item .dropdown-menu a:hover { color:var(--bs-primary);background-color: #fff !important;text-decoration: none; }
.navbar .navbar-nav .nav-item .cont {
  color: #000;
}
.menu_head::before { content:""; display:inline-block; position:absolute; left:0; top:0; }
.menu-webdev::before { background:url(../img/menu-web.svg) no-repeat 0 0; height: 27px; width: 27px; }
.menu-webdev2::before { background:url(../img/menu-web2.svg) no-repeat 0 0; height:31px; width:25px; }
.menu-fullstack::before { background:url(../img/menu-fullstack.svg) no-repeat 0 0; height: 27px; width: 27px; }
.menu-fullstack2::before { background:url(../img/menu-fullstack2.svg) no-repeat 0 0; height:26px; width:26px; }
.menu-designtoweb::before { background:url(../img/menu-dtw.svg) no-repeat 0 0; height: 27px; width: 27px; }
.menu-mobileapp::before { background:url(../img/menu-mobileapp.svg) no-repeat 0 0; height:26px; width:16px; }
.menu-mobileapp2::before { background:url(../img/menu-mobileapp2.svg) no-repeat 0 0; height:26px; width:26px; }
.menu-cmsdev::before { background:url(../img/menu-cms.svg) no-repeat 0 0; height:27px; width:27px; }
.menu-cmsdev2::before { background:url(../img/menu-cms2.svg) no-repeat 0 0; height:27px; width:31px; }
.menu-techtrends::before { background:url(../img/menu-tech.svg) no-repeat 0 0; height:26px; width:26px; }
.menu-ecomdev::before { background:url(../img/menu-ecommerce.svg) no-repeat 0 0; height:26px; width: 28px; }
.menu-ecomdev2::before { background:url(../img/menu-ecommerce2.svg) no-repeat 0 0; height:27px; width:23px; }
.menu-cloudservice::before { background:url(../img/menu-cloudservice.svg) no-repeat 0 0; height:28px; width:28px; }
.menu-cloudservice2::before { background:url(../img/menu-cloudservice2.svg) no-repeat 0 0; height:26px; width:26px; }
.menu-microsoft::before { background:url(../img/menu-microsoft.svg) no-repeat 0 0; height:27px; width:27px; }
.menu-about::before { background:url(../img/menu-about.svg) no-repeat 0 0; height:27px; width:27px; }
.menu-hiring::before { background:url(../img/menu-hiring.svg) no-repeat 0 0; height:27px; width:27px; }
.menu-blog::before { background:url(../img/menu-blog.svg) no-repeat 0 0; height:27px; width:27px; }
.menu-blockchain::before { background:url(../img/menu-blockchain.svg) no-repeat 0 0; height:26px; width:26px; }
.menu-iot::before { background:url(../img/menu-iot.svg) no-repeat 0 0; height:26px; width:31px; }
.menu-software::before { background:url(../img/menu-software.svg) no-repeat 0 0; height:26px; width:28px; }
.menu-digital::before { background:url(../img/menu-digital.svg) no-repeat 0 0; height:26px; width:28px; }
.menu-team::before { background:url(../img/menu-team.svg) no-repeat 0 0; height:26px; width:26px; }

.menu-ecommerceMarket::before { background:url(../img/menu-ecommerceMarket.svg) no-repeat 0 0; height:23px; width:23px; }
.menu-food::before { background:url(../img/menu-food.svg) no-repeat 0 0; height:26px; width:20px; }
.menu-elearning::before { background:url(../img/menu-elearning.svg) no-repeat 0 0; height:27px; width:27px; }
.menu-medical::before { background:url(../img/menu-medical.svg) no-repeat 0 0; height:26px; width:26px; }
.menu-taxi::before { background:url(../img/menu-taxi.svg) no-repeat 0 0; height:27px; width:29px; }


/*Header*/
.title_sec .year_exp { color:var(--bs-orange); display:flex; }
.title_sec .year_exp::before,
.title_sec .year_exp::after { background:url(../img/excellence@2x.png) no-repeat 0 0 / cover; content:""; display:inline-block; height:116px; width:75px; }
.title_sec .year_exp::before { transform:rotatey(180deg); }
.title_sec .year_exp .yearexp { font:77px/1 var(--bs-heading-font-family); letter-spacing:3.23px; }
.title_sec .year_exp .year { font:49px var(--bs-heading-font-family); letter-spacing:3.48px; line-height:1.1; }
.title_sec .year_exp .exp { font:var(--bs-body-font-size) var(--bs-heading-medium-font-family); letter-spacing:0.8px; }
.title_sec .year_exp .client { font:23px var(--bs-heading-medium-font-family); letter-spacing:0.97px; }
.title_sec .year_exp sup { top: -1.2em; font-size: 36px; line-height: 1; }

.caseStudy_sec { overflow:hidden; }
.caseStudy_sec .swiper-slide { transition:0.3s;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical; overflow: hidden; margin: 0; }
.caseStudy_sec .swiper-slide p {     margin: 0;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden; }
.caseStudy_sec .swiper-slide .media_box { position:relative; overflow:hidden; border-radius:15px; isolation:isolate; }
.caseStudy_sec .swiper-slide .media_box video + picture { opacity:0; }
.caseStudy_sec .swiper-slide img { display:block; height:auto; width:100%; }
.caseStudy_sec .swiper-slide video { height:auto; width:100%; position:absolute; object-fit:cover; }
.caseStudy_sec .swiper-slide.swiper-slide-active {}
.caseStudy_sec .swiper-pagination { position:static; margin:0 0 20px; }
.caseStudy_sec .swiper-pagination .swiper-pagination-bullet { position:relative; opacity:1; height:35px; width:35px; background:none; outline:none; }
.caseStudy_sec .swiper-pagination .swiper-pagination-bullet::after { content: ""; display: block; height:7px; width:7px; background: #000; border-radius: 100px; position: absolute; z-index: 9; left: 50%; right: 50%; bottom: 50%; top: 50%; transition:0.5s; transform: translate(-50%,-50%);}
.caseStudy_sec .swiper-pagination .swiper-pagination-bullet::before { content: ""; display: block; height:7px; width:7px; border-radius: 100px; position: absolute; z-index: 9; left: 50%; right: 50%; bottom: 50%; top: 50%; transition:0.5s; margin:-4.5px; }
.caseStudy_sec .swiper-pagination .swiper-pagination-bullet-active::before { border:1px solid #EF4C1C; left:0; right:0; bottom:0; top:0; margin:0; height:100%; width:100%; }
.caseStudy_sec .swiper-pagination .swiper-pagination-bullet-active::after { background:#EF4C1C; } 

.tech_sec { background:var(--bs-dark); color:var(--bs-white); overflow:hidden; }
.tech_sec .nav-pills a { background:none; color:#3E3E3E; font:24px var(--bs-heading-font-family); padding:0; line-height:1.3; white-space:nowrap;
appearance:none;
-webkit-appearance:none
}
.tech_sec .nav-pills  a.active { background:none; color:#fff; }
.tech_sec .tech_list li { font:14px 'helvetica_now_displaylight'; letter-spacing:0.22px; text-align:center; margin-bottom:10px; }
.tech_sec .tech_list a { padding: 10px; background: #fff; border-radius:13px; height: 63px; width: 63px; display: flex; margin-bottom:8px; }
.tech_sec .tech_list a img { max-width: 100%; }
.tabhead::before { content: ""; display: block; position: absolute; width: 1px; height: 100%; background: #3E3E3E; left: 33.33%; top: 0;  bottom: 0; }

.tech_sec .card-header { border:0; padding-left:0; padding-right:0; }
.tech_sec .card-header a { color:#fff; font-size:30px; }
.tech_sec .tab-content > .tab-pane { border:0; }

.stats_sec { font-size:22px; }
.stats_sec span{line-height: 24px;}
.stats_sec * { transition:initial; }
.stats_box .stats_value { color:var(--bs-primary); font:70px var(--bs-light-font-family); display:flex; align-items:center; justify-content:center; letter-spacing:-2px; }
.stats_box .digit-container { display:flex; flex-direction:column; line-height:150px; }
.stats_box ._number div[style] span[style] { text-align:center; display:flex !important; justify-content:center; } 
.partners_sec .partners_list { margin: 0; padding: 0; list-style: none; text-align: center; }
.partners_sec .partners_list li { display:inline-block; padding-left:calc(var(--bs-gutter-x) * .5); padding-right:calc(var(--bs-gutter-x) * .5); margin-top:3rem; width:32.33%;  }
.partners_sec .partners_list li img { max-width:100%; height:auto; }
.testimonials_sec .trust_wrap::before { background:var(--bs-primary); content:""; display:block; position:absolute; width:100%; height:70%; left:0; top:0; z-index:-1;  }
.testimonials_sec .trust_sec { font-size:16px; } 
.testimonials_sec .trust_sec p { margin-bottom:0; }
.trust_logo { width:100px;    flex:0 0 170px; }
.testimonials_sec .trust_sec .goodfirm_box .trust_logo { width:180px; }
.testimonials_sec .trust_sec .trustpilot_box .trust_logo { width:190px; }
.testimonials_sec .trust_sec::-webkit-scrollbar {
  display: none;
}

.testimonials_sec .inner_box { border-radius:20px; box-shadow:0 5px 15px rgba(0,0,0,0.05); margin:0 12px; }
.testimonials_sec .inner_box .rating { background:url(../img/rating.png) no-repeat 0 0; margin:0 0 20px; height:17px; width:95px; }
.testimonials_sec .swiper-slide { height:auto; }
.testimonials_sec .swiper-pagination { position:static; margin:20px 0 0; }
.testimonials_sec .author { color:var(--bs-primary); }
.testimonials_sec .swiper-button-next,
.testimonials_sec .swiper-button-prev { border:1px solid #fff; border-radius:100px; height:35px; width:35px; background-size:15px auto; }
.testimonials_sec .swiper-button-prev { background:url(../img/arrow-right-white.svg) no-repeat center center; transform:rotatey(180deg); }
.testimonials_sec .swiper-button-next { background:url(../img/arrow-right-white.svg) no-repeat center center; }
.testimonials_sec .swiper-button-prev:hover,
.testimonials_sec .swiper-button-next:hover { background-color:#000; border-color:#000; }
.testimonials_sec .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet { margin: 0 8px; }
.testimonials_sec .swiper-pagination-bullet { background:#d1d0d6; height:10px; width:10px; opacity:1; box-shadow:0 0 0px 4px transparent; }
.testimonials_sec .swiper-pagination-bullet-active { background:#fff; box-shadow:0 0 0px 4px var(--bs-orange-light) }
.blog_sec picture img { border-radius:20px; }
.blog_sec .date { color:var(--bs-primary); letter-spacing:0.28px; }

.blog_wrap .post_block { border-top:1px solid #cfcfcf; }
.blog_wrap .post_block:first-of-type { border:0; }
.blog_wrap .post_block .post_block_inner { overflow:hidden; padding:20px 0; position:relative; transition:0.5s; }
.blog_wrap .post_block .post_block_inner .post_link { position:absolute; left:0; top:0; right:0; bottom:0; }
.blog_wrap .post_block .date { color:var(--bs-primary); }

.blog_wrap .post_block .arrow { background:url(../img/arrow_right_black.svg) no-repeat 0 0 / cover; width:49px; height: 21px; position: absolute; right: 0; top: 50%; transform: translateY(-50%); }
.form_sec .row { background:var(--bs-gray-200); border-radius:25px; }
.form_sec .form_cont .inner_box  { z-index:9; }
.form_sec .form_cont { background:var(--bs-primary); color:var(--bs-white); border-radius:25px; position:relative; overflow:hidden; }
.form_sec .form_cont .social_link li { margin-right:15px; }
.form_sec .form_cont .social_link img { width:30px; height:30px; }
.form_sec .form_cont .contact_list li { padding-left: 50px; position: relative; }
.form_sec .form_cont a { color:#fff; }
/* .our-services-section .post_block p{position: absolute;bottom: -100%;}
.our-services-section .post_block.active p{bottom: 14px;} */
/* .our-services-section .post_block p{opacity: ;: none;}
.our-services-section .post_block.active p{display: block;} */
.form_sec .form_cont .social_link a { color:#fff; display:inline-block; vertical-align:top; }
.form_sec .form_cont a:hover { margin-top: -5px; }
.form_sec .form_cont .phone::before,
.form_sec .form_cont .mail::before { content: ""; display: block; height:30px; width:37px; position: absolute; left: 0; top: 2px; }
.form_sec .form_cont .phone::before { background: url(../img/phone2.svg) no-repeat 0 0 / cover; height: 25px; width: 25px; }
.form_sec .form_cont .mail::before { background: url(../img/mail2.svg) no-repeat 0 0 / cover; width:25px; height:19px; }
.form_sec .form_cont a:hover { margin-top:-5px; }
.form_sec .form_cont .circle_s { border-radius: 100%; height: 84px; width: 84px; background:rgba(255,255,255,0.5); position: absolute; bottom: 60px; right: 60px; z-index:0; z-index:1; }
.form_sec .form_cont .circle_l { border-radius: 100%; height: 300px; width: 300px; background:rgba(255,255,255,0.18); position: absolute; bottom: -150px; right: -150px; z-index:0;  }

.footer { background:var(--bs-dark); color:#fff; }
.footer a { color:#fff; }
.footer .linkstyle a { display:inline-block; position:relative; }
.footer .linkstyle a::after { background:#fff; display:block; content:""; height:1px; width:0; }
.footer .linkstyle a:hover::after { width:100%; }
.footer .contact_list li { padding-left:35px; position:relative; }
.footer .contact_list .mail a { border-bottom:1px solid #fff; padding-bottom:3px; }
.footer .contact_list .phone::before,
.footer .contact_list .mail::before { content:""; display:block; height:20px; width:20px; position:absolute; left:0; top:7px; }
.footer .contact_list .phone::before { background: url(../img/phone.svg) no-repeat 0 0; }
.footer .contact_list .mail::before { background: url(../img/mail.svg) no-repeat 0 0; }
.footer .ftr_btm { font-size:calc(var(--bs-body-font-size) - 2px);  }
.footer .social_link li { vertical-align:top; margin-right:4px; }
.footer .social_link a { border: 1px solid #fff; border-radius: 100px; width: 54px; height: 54px; line-height: 54px; text-align: center; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; }
.footer .social_link a img { filter:grayscale(1) }
.footer .social_link a:hover {
-webkit-transform:rotate(360deg);
-moz-transform:rotate(360deg);
-ms-transform:rotate(360deg);
-o-transform:rotate(360deg);
transform:rotate(360deg);
}
.goto_top .arrow_up { border: 1px solid #fff; border-radius: 100px; cursor:pointer; width: 54px; height: 54px; line-height: 54px; text-align: center; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; animation:updown 2s infinite; }


.parallex_sec { background-position:center center; background-size:cover; background-attachment:fixed; height:100vh; height:80vh;  }
.about_img { background-image:url(../img/about-team.jpg); }



/*About*/
.serving_business {}
.serving_business .business_box {}
.serving_business .business_box [class*="dot_"] {    display: block;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  position: absolute; } 
.serving_business .business_box .dot_1 {left: 12.5%;top: 16%;}
.serving_business .business_box .dot_2 {left: 11%;top: 32%;}
.serving_business .business_box .dot_3 {left: 24%;top: 75%;}
.serving_business .business_box .dot_4 {left: 26%;top: 56%;}
.serving_business .business_box .dot_5 {left: 41.5%;top: 20%;}
.serving_business .business_box .dot_6 {left: 52.5%;left: 49.5%;top: 71%;}
.serving_business .business_box .dot_7 {left: 51.5%;top: 51%;}
.serving_business .business_box .dot_8 {left: 54%;top:36%;}
.serving_business .business_box .dot_9 {left: 57%;top: 25%;}
.serving_business .business_box .dot_10 {left:65%;top: 15%;}
.serving_business .business_box .dot_11 {left: 63.5%;top: 37%;}
.serving_business .business_box .dot_12 {left: 78%;top: 43%;}
.serving_business .business_box .dot_13 {left: 79%;top: 64%;top: 65%;}
.serving_business .business_box .dot_14 {left: 91%;top: 76%;}

/* Responsive */
@media (min-width: 768px) {
.h_head { font-size:60px; }
.testimonials_sec .trust_sec { border-top: 1px solid #4794F5; }
.testimonials_sec .trust_sec .clutch_box,
.testimonials_sec .trust_sec .goodfirm_box { border-right:1px solid #4794f5; }
}

@media (min-width: 992px) {
.py-6 { padding-top: 6.5rem !important; padding-bottom: 6.5rem !important; }
.pt-6 { padding-top: 6.5rem !important; }
.pb-6 { padding-bottom: 6.5rem !important; }
.my-6 { margin-top: 6rem !important; margin-bottom: 6rem !important; }
.mt-6 { margin-top: 6.5rem !important; }
.mb-6 { margin-bottom: 6.5rem !important; }

@keyframes moveCursor1 {
from {
transform: scale(1);
}
to {
transform: scale(.8);
}
}
@keyframes moveCursor2 {
0% {
transform: scale(1);
}
50% {
transform: scale(3);
}
100% {
transform: scale(1);
opacity: 0;
}
}


.cursor { display: block; width: 20px; height: 20px; position: absolute; top: 50%; left: 50%; transform: translate(-50%); border-radius: 50%; background: transparent; pointer-events: none; z-index: 111; border:1px solid var(--bs-orange); transition: all 0.2s ease-out; animation:moveCursor1 .5s infinite alternate; }
.expand { background:transparent; animation:moveCursor2 .5s forwards; border:1px solid var(--bs-orange); }

.h_head { font-size:70px; }

h1 { font-size:56px; }
h2 { font-size:56px; }
h3 { font-size:36px; }
h4 { font-size:27px; }
h5 { font-size:25px; }
h6 { font-size:20px; }

.h2 { font-size:36px; }
.h3 { font-size:30px; }
.h4 { font-size:26px }

.h_large_h1 {}
.f30 { font-size:30px; }
.f60 { font-size:50px; }

body .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet { margin: 0 9px; }

.caseStudy_sec .swiper-container { overflow: visible; }
.caseStudy_sec .swiper-pagination { position:absolute; top:0 !important; bottom:inherit !important; }
.caseStudy_sec .swiper-slide { margin:0 0 100px; }
.caseStudy_sec .swiper-slide img { transition:0.7s; }
.caseStudy_sec .swiper-slide.swiper-slide-active { margin:100px 0 0; }
.caseStudy_sec .swiper-pagination .swiper-pagination-bullet { height:50px; width:50px; } 	

.tech_sec .card .card-header { display: none; }
.tech_sec .card .collapse { display: block; }
.tech_sec .nav-pills a  { font-size:36px; }
.tech_sec .card { border:0 !important; }

.stats_box .stats_value { font-size:90px; }

.partners_sec .partners_list li { padding-left:calc(var(--bs-gutter-x) * .75); padding-right:calc(var(--bs-gutter-x) * .75); width:24.33%; }
.partners_sec .partners_list li img { max-width:100%; height: auto; }

.testimonials_sec .trust_wrap .container { padding: 0 80px; }
.testimonials_sec .swiper-button-next,
.testimonials_sec .swiper-button-prev { height:55px; width:55px; background-size:auto auto; }
.testimonials_sec .swiper-pagination { margin:60px 0 0; }

.footer .ftr_btm ul { float:right; }

.blog_wrap * { transition: initial; }
.blog_wrap { transition: initial; }
.blog_wrap .button_sec { text-align:right; }
.blog_wrap .post_block .post_title { transition:0.5s; }
.blog_wrap .post_block .post_block_inner { padding:30px 50px 30px 0; }
.blog_wrap .post_block .post_image { position:absolute; left:0; top:0; z-index:9; width:350px; }
.blog_wrap .post_block .post_image::before { content:""; display:block; background:#fff; height:100%; position:absolute; width:100%; top: 0; left: 0; z-index: 1; transition: 0.5s; opacity: 0.5; } 
.blog_wrap .post_block .post_block_inner::before { background:#000; content:""; display:block; position:absolute; left:0; top:0; height:100%; width:0; transition:0.5s; z-index:-1; }
.blog_wrap .post_block .post_block_inner:hover::before { width:100%; }
.blog_wrap .post_block .post_block_inner:hover { padding-left:30px; }
.blog_wrap .post_block .post_block_inner:hover,
.blog_wrap .post_block:hover .date,
.blog_wrap .post_block:hover .post_title { color:#fff; }
.blog_wrap .post_block:hover .post_title { text-decoration:underline; }
.blog_wrap .post_block:hover .post_image::before { width:0; }
}
/* homepage css */

.resbtn{display: block;}
.resbtnMob{display: none;}

/* homepage css */
@media (min-width: 1200px) { 
.f60 { font-size:60px; }
.caseStudy_sec .swiper-slide p{display:block;}

.h_head { font-size:80px; }
.header .logo_sec { width:20%; flex:0 0 20%; }
.header .menu_sec { width:80%; flex:0 0 80%; }
.header .logo_sec { width:20%; flex:0 0 20%; }
.header .menu_sec { width:80%; flex:0 0 80%; }
.header .logo_sec { padding-top:26px; padding-bottom:26px; }
.header .logo_sec .logo_big { width:215px; }
.header .logo_sec .logo_small { width:33px; }
.header.headroom--unpinned .navbar { opacity:0; margin-top:-150px; }
.header.headroom--pinned .navbar { opacity:1; margin-top:0; }

.navbar .navbar-nav .nav-item { margin: 0 2px; }
.navbar .navbar-nav .nav-link { padding-left:7px; padding-right:7px; }
.navbar .navbar-nav .nav-link { position:relative; }
.navbar .navbar-nav .nav-link::before { content: ""; display: inline; position: absolute; bottom: 0; background:var(--bs-primary); width: 0px; height: 3px; transform: inherit; transition:0.5s; left:0; }
.navbar .navbar-nav .nav-link:hover::before  { width:100%; }
.navbar .navbar-nav .nav-item.last:hover .nav-link { border:none; }
.dropdown-toggle::after { border:0; height:8px; width:8px; border:2px solid #000; border-left:0; border-top:0; transform:rotate(45deg); border-radius:0px; margin-left:.500em; vertical-align:.200em; display:none; }
.navbar .dropdown-menu { box-shadow:0 0 10px 0 rgba(0,0,0,0.1);left: -24% !important; width: 123% !important; }
  
.tech_sec .nav-pills a  { font-size:48px; }
.stats_box .stats_value { font-size:130px; }
.partners_sec .partners_list li { padding-left:calc(var(--bs-gutter-x) * .75); padding-right:calc(var(--bs-gutter-x) * .75); width:auto; }

.form_sec .form_cont .circle_s { height:84px; width:84px; bottom:100px; right:100px; }
.form_sec .form_cont .circle_l { height:400px; width:400px; bottom:-200px; right:-200px; }

}

@media (min-width: 1400px) {
.h_head { font-size:90px; }
.header .logo_sec .logo_big { width:282px; }
.header .logo_sec .logo_small { width:43px; }

.header .logo_sec { width:22%; flex:0 0 22%; }
.header .menu_sec { width:78%; flex:0 0 78%; }
.navbar .navbar-nav .nav-item { margin: 0 5px; }
.navbar .navbar-nav .nav-link { padding-left:13px; padding-right:13px; }
/* .navbar .border-left::before { content:""; display:block; height:100%; width:2px; position:absolute; left:-5%; background:#cfcfcf; top:0; z-index:9; } */

.tab_cont_wrap::before { content: ""; display: block; width: 2px; position: absolute; left: -15%; background: #292929; top: -40%; z-index: 9; bottom: -40%; }

.stats_box .stats_value { font-size:170px; }
.partners_sec .partners_list li { padding-left:calc(var(--bs-gutter-x) * 1.5); padding-right:calc(var(--bs-gutter-x) * 1.5); }
}

@media (min-width: 1600px) {
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl { max-width:1440px; }
.h_head { font-size:108px; }
.header .logo_sec .logo_big { width:370px; }
.header .logo_sec .logo_small { width:56px; }
}

@media (max-width:1199px) {
.header .logo_sec .logo_big { width:280px; }
.header .logo_sec .logo_small { width:43px; margin-left:1px; }
.header .menu_sec { position:relative; width:50px; flex:0 0 90px; }
.header .collapse.show,
.header .collapse:not(.show) { display:block !important; height:100vh !important; overflow:scroll; }
.header .navbar-collapse  { background:#ececec; padding:0; position:fixed; left:-100%; width:100%; top:0; transition:0.2s; padding:100px 20px 50px; height:100vh; }
.header .navbar-collapse.show { left:0; transition:0.2s; }
.header .navbar-toggler { border: 0; margin: 0 15px 0 0; position: absolute; right: 0; top: 0; z-index: 99; padding: 0; }
.header .navbar-toggler:focus { box-shadow:0 0; background:none; }
.header .navbar-toggler .navbar-toggler-icon { background:#000; border-radius:17px; box-shadow:0 -9px 0, 0 9px 0; display:block; height:4px; width:30px; }
.header .navbar .navbar-nav .nav-item { border-top:1px solid #fff; box-shadow:0 -1px 0 #cfcfcf; position:relative !important; }
.header .navbar .navbar-nav .nav-item:first-child { border:0; box-shadow:0 0; }
.header .navbar .navbar-nav .nav-item > a { color:#000; display:block; padding:12px 40px 12px 0; padding-right:40px; } 
.header .navbar .navbar-nav .dropdown .dropdown > a { padding:7px 40px 7px 15px; }
.navbar .navbar-nav .nav-link + [data-bs-toggle="dropdown"] { display:none; }
.dropdown-toggle::after { position:absolute; right:0; top:24px; } 
.header  .navbar .dropdown .dropdown-toggle span { display:none; }

.dropdown-menu .list-group .list-group-item { background:none; }
.header .navbar .dropdown-menu { background:none; box-shadow:0 0 !important; border-radius:0 0; border:0; }
.header .navbar .dropdown-menu .container { max-width:100%; } 
.header .navbar .dropdown-menu li { position:relative; }
.header .navbar .dropdown-menu .dropdown-item { border-top:1px solid #efefef; padding:7px 15px; white-space:initial; }
.header .navbar .dropdown-menu .dropdown-item:hover {
  background-color: #fff !important;
}
.header .navbar .dropdown-menu li:first-child .dropdown-item { border:0; }
}
.brk{display: block;}
.marquee0>div {
  width: 100% !important
}
@media (max-width: 991px) {
  .brk{display: none;}
  .stats_box{flex:1 1 49.33%}
  .stats_box br{display: none;}
  .dropdown-toggle::after{border: none;}
.tech_sec .hscroll { overflow: hidden; overflow-x: scroll; margin: 30px 0; border-bottom: 1px solid #3E3E3E; }
.tech_sec .hscroll .nav a { width: auto; text-align: center; border-radius: 20px 20px 0 0; display:inline-block; padding: 20px; }
.tech_sec .nav-pills { flex-direction:row !important; flex-wrap: unset !important; }

.blog_wrap .post_block .post_image { display:none !important; } 
.blog_wrap .post_block .arrow { position: static; transform: inherit; margin: 10px 0 0; width: 35px; height: 15px; }
.resbtn{display: none;}
.resbtnMob{display: block;}
}
@media (min-width: 991px) {
  .f24 { font-size:24px; }
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{padding-right: calc(var(--bs-gutter-x) * .5);padding-left: calc(var(--bs-gutter-x) * .5);}

}
@media (max-width: 767px) {
.title_sec .year_exp::before, .title_sec .year_exp::after { height: 96px; width: 62px; } 
.title_sec .year_exp .yearexp { font-size:67px; }
.title_sec .year_exp sup { font-size:26px; }
.title_sec .year_exp .year { font-size:38px; }
.title_sec .year_exp .client { font-size:18px; }
.form_sec .form-check-inline { margin:0; width:45%; }

.serving_business .business_box .dot_1 {left: 12.5%;top: 16%;}
.serving_business .business_box .dot_2 {left: 11%;top: 32%;}
.serving_business .business_box .dot_3 {left: 21%;top: 75%;}
.serving_business .business_box .dot_4 {left: 26%;top: 56%;}
.serving_business .business_box .dot_5 {left: 39.5%;top: 12%;}
.serving_business .business_box .dot_6 {left: 52.5%;left: 47.5%;top: 62%;}
.serving_business .business_box .dot_7 {left: 47.5%;top: 42%;}
.serving_business .business_box .dot_8 {left: 51%;top: 30%;}
.serving_business .business_box .dot_9 {left: 52%;top: 19%;}
.serving_business .business_box .dot_10 {left:65%;top: 15%;}
.serving_business .business_box .dot_11 {left: 60.5%;top: 30%;}
.serving_business .business_box .dot_12 {left: 69%;top: 35%;}
.serving_business .business_box .dot_13 {left: 75%;top: 63%;top: 65%;}
.serving_business .business_box .dot_14 {left: 88%;top: 72%;}

}


@media (max-width: 575px) {
.header .logo_sec .logo_big { width:200px; }
.header .logo_sec .logo_small { margin:0; width:30px; }
.navbar-toggler span { margin: 6px auto; width:30px; }
.navbar-toggler[aria-expanded="true"] span:nth-child(1) {
-webkit-transform: translateY(7px) rotate(45deg);
-ms-transform: translateY(7px) rotate(45deg);
-o-transform: translateY(7px) rotate(45deg);
transform: translateY(7px) rotate(45deg);
}
.title_sec .year_exp::before, .title_sec .year_exp::after { height:76px; width:42px; }
.title_sec .year_exp .yearexp { font-size:50px; }
.title_sec .year_exp sup { font-size: 23px; }
.title_sec .year_exp .year { font-size: 26px; }
.form_sec .container { padding-right: calc(var(--bs-gutter-x) * 1); padding-left: calc(var(--bs-gutter-x) * 1); }
.form_sec .form_cont .circle_s { bottom: 40px; right: 40px;height: 54px; width: 54px; }
.form_sec .form_cont .circle_l { height: 200px; width: 200px; bottom: -100px; right: -100px; }

.goto_top .ttitle { display:none; }
.goto_top .arrow_up,
.footer .social_link a { height:40px; width:40px; }
.goto_top .arrow_up img,
.footer .social_link a img { width:20px; height:20px; }
}

@media (max-width: 480px) {
.form_sec .form-check-inline { width:100%; }
}

/* @media (max-width: 576px){
.container, .container-sm {
    max-width: 650px;
}
} */


/* m */

/* radio buttons css */
/* The container */
.checkbox-item {
    display: block;
    position: relative;
    padding-left: 26px;

    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-item input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-item:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-item input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-item input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-item .checkmark:after {
    left: 6px;
    top: 3px;
    width: 7px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .h-40 {height: 55px;}

  .l-28{line-height: 28px;}
  
/* radio button css end */


.link{
  color: #ef4c1c;
  font-size: 16px;
}
.nav-link{
  font-family: var(--bs-body-font-family);
}
