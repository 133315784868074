/* banner-section */
.anm-heading{display: flex;flex-wrap: wrap;}
.anim-txt{margin-right: 6px;
    display: flex;
    align-items: center;
      }
.anm-text{display: flex;flex-wrap: wrap;}
/* end */

/* gallery-section  */
.gallery-section .image-itm {height: 540px;position: relative;overflow: hidden;border-radius: 20px;}
.gallery-section  .img-an1 {background-image: url(../img/about-1.jpg);}
.gallery-section  .img-anm {position: absolute;height: 100%;width: 100%;left: 0;top: 0;-webkit-transform: scale(1.1);-ms-transform: scale(1.1);transform: scale(1.1);background-repeat: no-repeat;background-position: center center;background-size: cover;-webkit-transition: transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1);-o-transition: transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1);-webkit-transition: -webkit-transform 999mscubic-bezier(0.215, 0.61, 0.355, 1);transition: -webkit-transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1);transition: transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1);transition: transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1),-webkit-transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1);}
.gallery-section  .bg-w1.active::after {--webkit-transform: translateX(100%); -ms-transform: translateX(100%); transform: translateX(100%);}
.gallery-section  .bg-w2.active::after {--webkit-transform: translateX(-100%);-ms-transform: translateX(-100%);transform: translateX(-100%);}
.gallery-section  .bg-container::after {content: "";position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;background-color: #fff;-webkit-transition: transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1);-o-transition: transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1);-webkit-transition: -webkit-transform 999mscubic-bezier(0.215, 0.61, 0.355, 1);transition: -webkit-transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1);transition: transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1);transition: transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1),-webkit-transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1);}
.gallery-section  .bg-container {position: absolute;top: 0;height: 100%;width: 100%;overflow: hidden;background-color: rgba(0, 0, 0, 0.1);border-radius: 23px;}
.gallery-section  .img-an2 {background-image: url(../img/about-2.jpg);right: 0;}
.gallery-section figure img {width: 100%;border-radius: 20px;transition: transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1),-webkit-transform 999ms cubic-bezier(0.215, 0.61, 0.355, 1);box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;height: auto;}
/* end */



/* core_values_css */
.core_values_section .accordion-item{    border: none;background-color: transparent;border-bottom:1px solid #c4c4c4;}
.core_values_section .accordion-item:last-child{border-bottom: none;}
.core_values_section .accordion-button{padding: 22px 0px;}
.core_values_section .accordion-button:focus{box-shadow: none; border-bottom: none;}
.core_values_section .accordion-button:not(.collapsed){background-color: transparent;}
.core_values_section .accordion-button:not(.collapsed)::after{background-image: url("../img/minus-icon.svg");transform: inherit;    transform: inherit; }
.core_values_section .accordion-button::after{background-image: url("../img/plus-svgrepo-com.svg");transform: inherit;   color: #454545;}
.core_values_section  .accordion-collapse{text-align: left;}
.accordion-button{
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
.accordion-button:focus{
  border: none;
  outline: none;
}
/* core_values_css end */

/* copartner section */
.co-partner-section .firmicons{position: relative;}
.co-partner-section .firmicons .circle{position: absolute  ;border-radius: 50%;width: 348px;top: 50%;left: 50%;transform: translate(-50%,-50%);height: 165px;width: 165px;border:1px solid #c4c4c4}
.co-partner-section .firmicons img{  width: 100%;object-fit: contain;display: block;margin: auto;z-index: 1;position: relative;}
.blink{position: relative;}
.serving_business .business_box  .blink, .blink::before {content: " ";margin: 15px;width: 5px;height: 5px;border-radius: 50%;margin: 0 auto;transition: all 0.3s;background-color: #0072FF;}
.serving_business .business_box  .blink, .blink::after {content: " ";margin: 15px;width: 5px;height: 5px;border-radius: 50%;margin: 0 auto;transition: all 0.3s;background-color: #0072FF;}
/* .blink1 {
    height: 100px;
    position: relative;
    width: 100px;
  } */
  .blink1{position: relative;}
  .blink1::after{
    content: '';
    height: 5px;
    width: 5px;
    position: absolute;
    background: #0072ff;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .blink1 > div {
    animation: growAndFade 3s infinite ease-out;
    background-color: #0072ff;
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
  .blink1 .circle1 {
    animation-delay: 1s;
  }
  .blink1 .circle2 {
    animation-delay: 2s;
  }
  .blink1 .circle3 {
    animation-delay: 3s;
  }
  
  @keyframes growAndFade {
    0% {
      opacity: 0.25;
      transform: scale(0);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
.blink::before {animation: mymove 2s infinite;position: absolute;background-color:#0072FF}  
@-webkit-keyframes mymove {50%  {transform: scale(5);opacity: 0}
100%   {transform: scale(5);opacity: 0}}
/* copartners section end */

/* media query */
    @media (max-width: 991px) {}
@media (min-width: 991px) {.co-partner-section .firmicons .circle{height: 200px;width: 200px;border:1px solid #c4c4c4}.co-partner-section .firmicons img{width: 150px;} .anim-txt > div{color:#000!important}}
@media (min-width:1199px) {.co-partner-section .firmicons img{padding: 15px;}.co-partner-section .firmicons .circle{ height: 310px; width: 310px;}.co-partner-section .firmicons img{width: 218px;padding:0!important}}
@media (max-width: 1400px) {.co-partner-section .firmicons .circle{ height: 250px; width: 250px;}}
@media (max-width: 990px) {.co-partner-section .firmicons .circle{    height: 150px;width: 150px;}}
@media (max-width: 450px) {.co-partner-section .firmicons .circle{    height: 130px;width: 130px;}.co-partner-section .firmicons  img{padding: 0!important;}}
@media (max-width: 367px) {.co-partner-section .firmicons .circle{    height: 120px;width: 120px;}}


@media (max-width: 1600px) {.parallex_sec{    background-attachment: local;height: 550px;}}
@media (max-width: 767px) {.gallery-section .image-itm{height: 420px;}.parallex_sec{    background-attachment: local;height: 300px;}}
/* media query end */
