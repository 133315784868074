/* gallery-section  */
.portfolio-section .image-item {height: 540px;position: relative;overflow: hidden;border-radius: 20px;}
.portfolio-section  .img-anim {position: absolute;height: 100%;width: 100%;left: 0;top: 0;-webkit-transform: scale(1.1);-ms-transform: scale(1.1);transform: scale(1.1);background-repeat: no-repeat;background-position: center center;background-size: cover;-webkit-transition: transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);-o-transition: transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1);-webkit-transition: -webkit-transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1);transition: -webkit-transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1);transition: transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1);transition: transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1),-webkit-transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1);}
/* .portfolio-section  .img-anim:hover  */
.portfolio-section  .bg-right-overlay.active::after {--webkit-transform: translateX(100%); -ms-transform: translateX(100%); transform: translateX(100%);}
.portfolio-section  .bg-left-overlay.active::after {--webkit-transform: translateX(-100%);-ms-transform: translateX(-100%);transform: translateX(-100%);}
.portfolio-section  .bg-container::after {content: "";position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;background-color: #fff;-webkit-transition: transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1);-o-transition: transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1);-webkit-transition: -webkit-transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);transition: -webkit-transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1);transition: transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1);transition: transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1),-webkit-transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1);}
.portfolio-section  .bg-container {position: absolute;top: 0;height: 100%;width: 100%;overflow: hidden;background-color: rgba(0, 0, 0, 0.1);border-radius: 23px;}
.portfolio-section  .portfolio-img1 {background-image: url(../img/portfolioimg-1.png);}
.portfolio-section  .portfolio-img2 {background-image: url(../img/portfolioimg-2.png);right: 0;}
.portfolio-section  .portfolio-img3 {background-image: url(../img/portfolioimg-3.png);right: 0;}
.portfolio-section  .portfolio-img4 {background-image: url(../img/portfolioimg-4.png);right: 0;}
.portfolio-section  .portfolio-img5 {background-image: url(../img/portfolioimg-5.png);right: 0;}
.portfolio-section  .portfolio-img6 {background-image: url(../img/portfolioimg-6.png);right: 0;}
.portfolio-section figure img {width: 100%;border-radius: 20px;transition: transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1),-webkit-transform 0.9s  cubic-bezier(0.215, 0.61, 0.355, 1);box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;height: auto;}
/* end */




/* maxi-width */
@media only screen and (max-width: 767px) {
    .portfolio-section  .mt-6{margin-top: 0!important;}
    .portfolio-section .image-item{height: 450px;}
}
@media only screen and (max-width: 450px) {
    .portfolio-section .image-item{height: 300px;}
}
/* end */